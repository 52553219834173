import React, { useState, useEffect } from 'react';
import './App.css';
import Select from 'react-select';
import { Container, Form, Button, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import AWS from 'aws-sdk';
import  { useRef } from 'react';
import ExcelJS from 'exceljs';
import config from './appenvdata.json';
import 'font-awesome/css/font-awesome.min.css';
import { Amplify, Auth } from 'aws-amplify';

import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path

import { useAuth } from './AuthContext';

const envValue = config.env;

const Bucket=config.ModEstBucketName

Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {
  const toolName = 'Modernization Estimator'; 
  const [sourceTech, setSourceTech] = useState('');
  const [targetTech, setTargetTech] = useState('');
  const [patterntech, setPatternTech] = useState('');
  const [selectedPattern, setSelectedPattern] = useState('');


  const [devopsOption, setDevopsOption] = useState('');
  const [securityOption, setSecurityOption] = useState('');
  const [sourceTargetMap, setSourceTargetMap] = useState({});
  const [sourceTargetPatternMap, setSourceTargetPatternMap] = useState({});
  const [applicationQuestions, setApplicationQuestions] = useState([]);
  const [appParsedData, setAppParsedData] = useState(null); 
  const [loc, setLoc] = useState(null); 
  const [effortValues, setEffortValues] = useState([]);
  const [loading, setLoading] = useState(false); // State to track loading state
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  

  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: config.region,
  
  });


 const fetchUserData = async () => {
  try {

   
   setUserEmail(userAttributes.email);
   setUserFirstName(userAttributes.given_name);
   setUserLastName(userAttributes.family_name);
 
    //console.log('Cognito Username:', userData.attributes.email); // Print the Cognito username
  } catch (error) {
    console.log('Error fetching user data: ', error);
  }
};


  useEffect(() => {
    
 fetchUserData()

    filecount()
    
    setApplicationQuestions([]); 
    fetchFileFromS3();

    setPatternTech([]);
    
  }, []);


  const fetchUpdatedFileFromS3 = (fileName) => {
   
    const bucketName = Bucket;
    const key = `Modernization-Estimator/Updated/${fileName}.xlsx`;

    const s3 = new AWS.S3({ region: config.region, credentials: credentials });
    const params = {
      Bucket: bucketName,
      Key: key,
    };
  
    s3.getObject(params, (err, data) => {
     
      if (err) {
       
        // If the file is not found, call the function again after 5 seconds
        setTimeout(() => fetchUpdatedFileFromS3(fileName), 5000);
        return;
      }
      
      const workbook = XLSX.read(data.Body, { type: 'buffer' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Extracting first non-null EFFORT (PM) value
      let effortValue = null;
      for (let i = 1; i < parsedData.length; i++) {
        const rowData = parsedData[i];
        const effortPMIndex = parsedData[0].indexOf('EFFORT (PM)'); // Assuming the header row contains column names
        
        if (effortPMIndex !== -1) {
          const effortPMValue = rowData[effortPMIndex];
          if (effortPMValue !== null && effortPMValue !== undefined) {
            effortValue = effortPMValue;
            break; // Exit the loop once a non-null value is found
          }
        }
      }
      
  
if (effortValue !== null && effortValue !== undefined) {
  // Round to two decimal places
  const roundedEffortValue = parseFloat(effortValue).toFixed(1);
  setEffortValues([roundedEffortValue]);

 
  setLoading(false);
  setEffortValues([roundedEffortValue]); 
  setSubmitButtonDisabled(false); 
   

  setSubmitButtonDisabled(false);
}
else {
        // If no non-null value is found, call the function again after 5 seconds
        setTimeout(() => fetchUpdatedFileFromS3(fileName), 5000);
      }
    });
  };

  const fetchFileFromS3 = async () => {
    AWS.config.update({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: config.region,
    
    });
    
  
    filecount()

    const bucketName = Bucket;
    const key = 'Modernization-Estimator/ModEst.xlsx'; 

    const s3 = new AWS.S3({ region: config.region, credentials: credentials });
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error fetching file from S3:', err);
        return;
      }
      
      const workbook = XLSX.read(data.Body, { type: 'buffer' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });


      const appsheetName = workbook.SheetNames[1];
      const appsheet = workbook.Sheets[appsheetName];
      let AppParsedData = XLSX.utils.sheet_to_json(appsheet, { header: 1 });

      
       setAppParsedData(AppParsedData)
      
      parseExcelData(parsedData);
    
    });

  };


const filecount = async () => 
{

  
  const s3 = new AWS.S3({ region: config.region, credentials: credentials });

   const params = {
    Bucket: Bucket,
    Prefix: "Modernization-Estimator/Updated/",
  };


  s3.listObjectsV2(params, (err, data) => {
    if (err) {

      console.error('Error listing S3 objects:', err);
      return;
    }
    else{const numberOfFiles = data.Contents.length;
      
      setFileCount(numberOfFiles);}
    
    })
  };

  const parseExcelData = (data) => {

    const parsedSourceTargetMap = {};
    const parsedSourceTargetPatternMap = {};
    const parsedApplicationQuestions = [];
    const uniqueSources = new Set();
    const uniqueTargets = new Set();

    for (let i = 1; i < data.length; i++) {
        const source = data[i][0].trim(); 
        const target = data[i][1];
        const pattern = data[i][2];

        if (!parsedSourceTargetMap[source]) {
            parsedSourceTargetMap[source] = [];
        }
        if (!parsedSourceTargetPatternMap[source]) {
            parsedSourceTargetPatternMap[source] = {};
        }

        if (!parsedSourceTargetPatternMap[source][target]) {
            parsedSourceTargetPatternMap[source][target] = [];
        }

        parsedSourceTargetMap[source].push({ value: target, label: target });
        parsedSourceTargetPatternMap[source][target].push({ value: pattern, label: pattern });

        uniqueSources.add(source);
        uniqueTargets.add(target);
    }

    setSourceTech(Array.from(uniqueSources));
    setTargetTech(Array.from(uniqueTargets));

    // Set patterns based on both source and target
    const patterns = {};
        for (const source of uniqueSources) {
            patterns[source] = {};
            for (const target of uniqueTargets) {
       
              if (parsedSourceTargetPatternMap[source] && parsedSourceTargetPatternMap[source][target] !== undefined) {

                  patterns[source][target] = parsedSourceTargetPatternMap[source][target];
                } else {
                    patterns[source][target] = [];
                }
            }
        }

    setSourceTargetPatternMap(patterns);
   

    const headers = data[0].slice(2); 
    for (let i = 0; i < headers.length; i++) {
        //parsedApplicationQuestions.push({ question: headers[i], technology: '', difficultyPoints: '', score: '' });
    }

    setSourceTargetMap(parsedSourceTargetMap);
    setApplicationQuestions(parsedApplicationQuestions);
};


const handleSourceTechChange = (selectedOption) => {
    
    setSourceTech(selectedOption ? selectedOption.value : '');
    
    setTargetTech('');
    setApplicationQuestions([]) 
    setPatternTech('')

    setSelectedPattern(null);
  };
 
const handleTargetTechChange = (selectedOption) => {
 
   const targetTech = selectedOption.value;

    if (sourceTech && targetTech) {
     
      const sourceWithoutSpaces = sourceTech.replace(/\s/g, '').toLowerCase();
      const targetWithoutSpaces = targetTech.replace(/\s/g, '').toLowerCase();
  
      const sourceMatchingRows = appParsedData.filter(row => {
          const rowWithoutSpaces = row[0].replace(/\s/g, '').toLowerCase();
          return sourceWithoutSpaces.includes(rowWithoutSpaces) || sourceWithoutSpaces === rowWithoutSpaces;
      });
      
  
      if (sourceMatchingRows.length > 0) {
          const filteredQuestions = [];
          
          sourceMatchingRows.forEach(questionsRow => {
              const rowTechnologies = questionsRow[1].split(',').map(tech => tech.trim().replace(/\s/g, '').toLowerCase());
              const targetMatch = rowTechnologies.some(tech => tech === targetWithoutSpaces);
              


              if (targetMatch) {
                  const questions = questionsRow.slice(2).filter((_, index) => index % 3 === 0); 
                  const optionslist = questionsRow.slice(3).filter((_, index) => index % 4 === 0);
                  const info =  questionsRow.slice(4).filter((_, index) => index % 5 === 0);
      
                  questions.forEach((question, index) => {
                      const options = optionslist[index].split(',').map(option => option.trim());
                      filteredQuestions.push({ question, options, technology: '', info });

                      //filteredQuestions.push({ question, options, technology: '', difficultyPoints: difficultyPoints[index], score: '' });
                  });
              }
          });
  
          setApplicationQuestions(filteredQuestions);
      } else {
          setApplicationQuestions([]); 
      }
  } else {
      setApplicationQuestions([]);
  }

    setSelectedPattern(null);
    setTargetTech(selectedOption ? selectedOption.value : '');
    
    
    setPatternTech('');
    if (sourceTech && selectedOption) {
      const patterns = sourceTargetPatternMap[sourceTech][selectedOption.value];
      if (patterns && patterns.length > 0) {
       
        setPatternTech(patterns);
      }
    }


  };
 
  const handleApplicationQuestionChange = (index, property, value) => {
    const updatedQuestions = [...applicationQuestions];
    updatedQuestions[index][property] = value;
    setApplicationQuestions(updatedQuestions);
  };

 
  const generateFileName = () => {
    const currentDate = new Date().toISOString().slice(0, 10); 
    const randomString = Math.random().toString(36).substring(7);

    return `ModEst_${currentDate}_${randomString}.json`;
};

AWS.config.update({
  accessKeyId: credentials.accessKeyId,
  secretAccessKey: credentials.secretAccessKey,
  sessionToken: credentials.sessionToken,
  region: config.region,

});
  const handleSubmit = async (event) => {
    setLoading(true);
    setEffortValues('')
    const bucketName = Bucket;
    const key = 'Modernization-Estimator/Input/'+generateFileName(); 

    event.preventDefault();

    const formData = {
        'Source Technology': sourceTech,
        'Target Technology': targetTech,
        'Pattern':selectedPattern.value,
        'DEVOPS': devopsOption,
        'Security': securityOption,
        'LOC': loc,
        'Application Questions': applicationQuestions.map(question => ({
            'Question': question.question,
            'Technology': question.technology,
            
        }))
    };

    const jsonData = JSON.stringify(formData);

    const params = {
        Bucket: bucketName,
        Key: key,
        Body: jsonData,
        ContentType: 'application/json' 
    };
    
    const s3 = new AWS.S3({ region: config.region, credentials: credentials });
    try {
        await s3.upload(params).promise();
        setSubmitButtonDisabled(true);


        const fileNameWithExtension = key.split('/').pop(); 
        const fileNameWithoutExtension = fileNameWithExtension.split('.').slice(0, -1).join('.'); 


        
        
        fetchUpdatedFileFromS3(fileNameWithoutExtension)

        
    } catch (error) {
        
    }


    
};

  const handlePatternChange = (selectedOption) => {
    setSelectedPattern(selectedOption);
  };

  const getUniqueTargets = (options) => {
  
    if (!options) return [];
  
   
    const uniqueTargets = new Set();
    
    
    return options.filter(option => {
      if (!uniqueTargets.has(option.value)) {
        uniqueTargets.add(option.value);
        return true;
      }
      return false;
    });
  };
  
  const handleUpdateDB = async (event) => {
    
    try {
     
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };


  return (

    
    <div>
      <h2 className="heading">Modernization Estimator</h2>
      <div className="App">
        <Container>
          <div className="product-description">
            This tool provides a quick ball-park estimate for modern application architecture.
          </div>
          <Form className="scrollable-form" onSubmit={handleSubmit}>

    
    <div className="row">
        <div className="col-md-4">
            <Form.Group className="form-field" controlId="sourceTechnology">
                <Form.Label>Source Technology:</Form.Label>
                <Select
                    options={Object.keys(sourceTargetMap).map((source) => ({ value: source, label: source }))}
                    onChange={handleSourceTechChange}
                    placeholder="Select Source Technology"
                    required
                />
            </Form.Group>
        </div>
        <div className="col-md-4">
            <Form.Group className="form-field" controlId="targetTechnology">
                <Form.Label>Target Technology:</Form.Label>
                <Select
                    options={sourceTech ? getUniqueTargets(sourceTargetMap[sourceTech]) : []}
                    onChange={handleTargetTechChange}
                    isDisabled={!sourceTech}
                    placeholder="Select Target Technology"
                    required
                />
            </Form.Group>
        </div>
        <div className="col-md-4">
            <Form.Group className="form-field" controlId="pattern">
                <Form.Label>Pattern:</Form.Label>
                <Select
                    options={patterntech}
                    value={selectedPattern ? selectedPattern : null}
                    onChange={handlePatternChange}
                    placeholder="Select Pattern"
                    required
                />
            </Form.Group>
        </div>
        
    </div>

    <div className="row">

    <div className="col-md-4">
    <Form.Group  className="form-field" controlId="exampleText">
            <Form.Label>Lines of Code </Form.Label>
            <Form.Control type="text" pattern="[0-9]+"  placeholder="Enter digits" required onChange={(e) => setLoc(e.target.value) }/>
            </Form.Group>

</div>
        
        <div className="col-md-4">
          
           
        </div>
      
    </div>

    {sourceTech && targetTech && applicationQuestions.length > 0 && (
        <>
            <h3>Application Questions</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
    {applicationQuestions.map((question, index) => (
        <tr key={index}>
            <td> {question.info}</td>
           
            <td>
                {question.options.includes('Yes') || question.options.includes('No') ? (
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        checked={question.technology === 'Yes'}
                        onChange={(e) => handleApplicationQuestionChange(index, 'technology', e.target.checked ? 'Yes' : 'No')}
                        label=""
                        className="custom-checkbox" 
                        // Add custom class for checkbox
                    />
                ) : (
                    <Form.Control
                        type={question.options.some(option => option.includes('-')) ? 'number' : 'text'}
                        value={question.technology}
                        onChange={(e) => handleApplicationQuestionChange(index, 'technology', e.target.value)}
                        min={question.options.some(option => option.includes('-')) ? 1 : undefined}
                        max={question.options.some(option => option.includes('-')) ? parseInt(question.options[0].split('-')[1]) : undefined}
                        placeholder="Number"
                        style={{ width: "100px" }} // Adjust the width as needed
                        className="custom-input"
                        required // Add custom class for digit input
                    />
                )}
            </td>
        </tr>
    ))}
</tbody>

            </Table>
        </>
    )}


<div className="row">

<div className="col-md-4">
{effortValues.length > 0 && (
<Form.Group  className="form-field" controlId="exampleText">
        <Form.Label >EFFORT (Person Month)</Form.Label>
        <br></br>
       
        <b>{effortValues.length > 0 ? effortValues[0] : ''}</b>
        
       
        </Form.Group>
      )}
</div>
    
</div>
 
    <Button  variant="primary" type="submit" disabled={submitButtonDisabled}  onClick={handleUpdateDB}>
        Submit
    </Button>
    {loading && <p style={{ marginTop: '10px', fontStyle: 'italic', color: '#555' }}>Calculating...</p>}

</Form>

        </Container>
      </div>
      
      <div className="product-description">
      <div className="container">
      <h3>About</h3>
      <p>Owner: <span id="version-number"> <b><a class="phone-link" href="https://phonetool.amazon.com/users/aloksinr">Alok Singh</a></b> </span></p>
       <p>Version number: <span id="version-number"><b>1.0</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>May 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>{fileCount}</b></span></p>
        <br></br>
         
        <h3>Version History</h3>
        
        <div className="version-wrapper">
        <p><span id="version-number"><b>Version 1.0</b></span></p>
        <p>Provides the estimation for moderninzing application in Person Month (PM) unit</p>
        
        </div>
        
        <br></br>
        <h3>🚀 Updates coming soon...</h3>
       
       <p>Upcoming features will include support for other technology stacks</p>
        
      </div>
    </div>
    </div>
  );
}

export default App;
