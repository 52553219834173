// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Auth } from 'aws-amplify';
import config from './appenvdata.json';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [userAttributes, setUserAttributes] = useState(() => {
    const savedAttributes = localStorage.getItem('userAttributes');
    return savedAttributes ? JSON.parse(savedAttributes) : null;
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setUserAttributes(decodedToken);
        } catch (error) {
          
          console.error('Error decoding token:', error);
        }
      }
    };

    fetchUserData();
  }, [token]);

  const handleAuthentication = async () => {
    // Implement your authentication logic here
  };

  const logout = async () => {
    await Auth.signOut();
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userAttributes');
  };

  return (
    <AuthContext.Provider value={{ token, userAttributes, setToken, handleAuthentication, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
