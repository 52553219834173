import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import 'font-awesome/css/font-awesome.min.css';
import { Container } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path

import { useAuth } from './AuthContext';

Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}
function App() {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: config.region,
  
  });
  
  const { token, userAttributes, logout } = useAuth();
  let accessKeyId;
  let secretAccessKey;
  const toolName = 'Estimate Validator'; 
  
  
  const [uploadPresignedUrl, setUploadPresignedUrl] = useState(null);
  const [fileStatus, setFileStatus] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  
  const bucketName = config.estimatebucketName ; 
 
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
 


  useEffect(() => {
  
    fetchUserData();

  }, []);
   const s3 = new AWS.S3({
      accessKeyId: accessKeyId, // Replace with your AWS access key
      secretAccessKey: secretAccessKey, // Replace with your AWS secret key
    });

  const [fileCount, setFileCount] = useState(0);

  useEffect(() => {
   

    const params = {
      Bucket: bucketName,
      Prefix: "output/",
    };


    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error('Error listing S3 objects:', err);
        return;
      }
      else{const numberOfFiles = data.Contents.length;
        setFileCount(numberOfFiles);}
      

      // Count the number of files in the folder
      
    });
  },);


  const fetchUserData = async () => {
    try {
     // const userData = await Auth.currentAuthenticatedUser();
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
      //console.log('Cognito Username:', userData.attributes.email); // Print the Cognito username
    } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };


  const readFileAndGetName = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = function(event) {
        try {
          // Parse the file content as JSON
          const fileContent = JSON.parse(event.target.result);
          const name = fileContent.Name; // Note the correct case sensitivity
          
          if (name) {
            resolve(name); // Resolve the promise with the name
          } else {
            reject(new Error('Name field not found in JSON.'));
          }
        } catch (error) {
          reject(new Error('Error parsing JSON: ' + error.message));
        }
      };
  
      reader.onerror = function(error) {
        reject(new Error('Error reading file: ' + error.message));
      };
  
      // Read the uploaded file as text
      reader.readAsText(file);
    });
  };


AWS.config.update({
  accessKeyId: credentials.accessKeyId,
  secretAccessKey: credentials.secretAccessKey,
  sessionToken: credentials.sessionToken,
  region: config.region,

});

  const generateRandomFileName = () => {
    const length = 10;
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const randomFile = generateRandomFileName();

 



  const checkFileExists1 = async (presignedUrl) => {
    try {
      //alert('CHEKCING CHECKING..... '+presignedUrl)
      const response = await fetch(presignedUrl, { method: 'GET' });
      return response.ok; // File exists if the HEAD request returns a successful response
    } catch (error) {
      return false; // File does not exist or other error occurred
    }
  };

  const generatePresignedUrl = async (fileName) => {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 3600, // URL expiration time in seconds (adjust as needed)
    };

    const presignedUrl = await s3.getSignedUrlPromise('getObject', params);
    return presignedUrl;
  };


  const startCheckingFile = async (fileName, interval) => {
    const presignedUrl = await generatePresignedUrl(fileName);
  
    let exists = await checkFileExists1(presignedUrl);
  
    while (!exists) {
      //alert('LOOPPPPPPP')
      await new Promise((resolve) => setTimeout(resolve, interval));
      exists = await checkFileExists1(presignedUrl);
    }
  
    setDownloadLink(presignedUrl);
    setFileStatus(' ')
    //setFileStatus('File found.');
  };
  const fileInputRef = useRef(null);
  const getUploadPresignedURL = () => {
    
    
    setFileStatus('');
    setDownloadLink('')
    const modifiedFileName = randomFile + '.json';

    const uploadParams = {
      Bucket: bucketName,
      Key: 'input/' + modifiedFileName,
      Expires: 600,
      ContentType: 'application/octet-stream',
      ACL: 'private',
    };


    const s3 = new AWS.S3({
      accessKeyId: accessKeyId, // Replace with your AWS access key
      secretAccessKey: secretAccessKey, // Replace with your AWS secret key
    });

    s3.getSignedUrl('putObject', uploadParams, (err, url) => {
      if (err) {
        console.error('Error:', err);
        return;
      }

      // Save the presigned URL for uploading
      setUploadPresignedUrl(url);

      // Call uploadFile with the presigned URL
      uploadFile(url, modifiedFileName);
    });
  };
  

 


  const uploadFile = async (presignedUrl, modifiedFileName) => {
   
  
    if (!presignedUrl) {
      console.error('Upload Presigned URL is not available.');
      return;
    }
  
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];
  
    if (!file) {
      console.error('No file selected.');
      return;
    }
  
    try {
      // Upload the file
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload file. Status: ' + response.status);
      }
  
      // Reset the file input
      fileInput.value = "";
      // Read the file and get the name
      const customer_name = await readFileAndGetName(file);
      // Update file status and perform other actions
      setFileStatus('File uploaded successfully.\n Your file is being restructured. Please wait...');
      
     
      await addEntryToDynamoDB(customer_name,toolName,userEmail, userFirstName,userLastName);

  
      // Start checking for the file
      startCheckingFile('output/' + modifiedFileName.slice(0, -5) + '.xlsx', 3000);
    } catch (error) {
      console.error('Error:', error);
      setFileStatus('File upload failed.');
    }
  };
  const hasNonEmptyText = /\S/.test(fileStatus);



  return (
    
    <div>
  {token ? (
    <div>
      
      <h2 className="heading">Estimate Validation</h2>

      <Container>
        <div className="product-description">
          Tool designed to efficiently retrieve a properly structured datasheet from an S3 pricing calculator estimation file. Please download the S3 pricing estimation in JSON format and upload.
        </div>

        <div className="container1">
          <div className="product-description"><b>Upload S3 pricing JSON File</b></div>

          <div className="file-input-container">
            <label className="amazon-file-input">
              <span>Choose File</span>
              <input
                type="file"
                id="fileInput"
                accept=".json"
                ref={fileInputRef}
                onChange={getUploadPresignedURL}
              />
            </label>

            &nbsp;<div>
              {downloadLink && (
                <a
                  className="amazon-file-input1"
                  href={downloadLink}
                  download
                >
                  <span>Download</span>
                </a>
              )}
            </div>
          </div>

          {hasNonEmptyText && (
            <div className="success-message with-background">
              {fileStatus}
            </div>
          )}

          <div>
            <br />
          </div>
        </div>
      </Container>

      <div className="product-description">
        <div className="container">
          <h3>About</h3>
          <p>Owner: <span id="version-number"><b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
          <p>Version number: <span id="version-number"><b>1.3</b></span></p>
          <p>Last Updated: <span id="last-updated-date"><b>July 2024</b></span></p>
          <p>Asset use count: <span id="asset-use-count"><b>{fileCount}</b></span></p>
          <br />

          <h3>Version History</h3>

          <div className="version-wrapper">
            <p><span id="version-number"><b>Version 1.0</b></span></p>
            <p>1. Extract server specifications CPU, Memory for corresponding instance types</p>
            <p><span id="version-number"><b>Version 1.1</b></span></p>
            <p>1. Improved functionality to Extract server specifications CPU Make for corresponding instance types</p>
            <p><span id="version-number"><b>Version 1.2</b></span></p>
            <p>1. Adding modernization status<br />
              2. Enhanced functionality through the inclusion of credit calculation.
            </p>
            <p><span id="version-number"><b>Version 1.3</b></span></p>
            <p>1. All nested subgroups are now captured to simplify your estimate validation. The “Group Name” field has been updated to reflect all nested groups from the main group.</p>
          </div>

          <br />
          <h3>🚀 Updates coming soon...</h3>

          <p>
            1. Add Feedback: Option for users to submit feedback for the tool.<br />
            2. Generate Datasheet with additional Storage and DB Specifications
          </p>
        </div>

        <br />
      </div>
    </div>
  ) : (
    <p>Please log in.</p>
  )}
</div>

  );
}

export default App;
